import React from "react";
import {NavLink} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import {useTranslation} from "react-i18next";
import {hasPermission} from "../../utils/Authentication";

const CCOperationsTab = (props) => {
  const {t, i18n} = useTranslation();

  function onPaths(paths) {

    return (match, location) => {
      return location.pathname.match(paths);
    };
  }

  return (
    <div className="xindicate text-center trucks-tab-container m-rl-m-16 d-flex cc-tab">
      {hasPermission("CCO.DASHBOARD") &&
        <NavLink to={'/cco/dashboard'} className={"farm-header-toggles"} activeClassName="plot-tog-active">
          <div id={'tabSetting'} className="">
            <FeatherIcon className="icon-in-tab farm-tab-icon-color" icon="grid"/><span
            className={"farm-tab-yield"}>{t("title.DASHBOARD")}</span></div>
        </NavLink>}
      {hasPermission("CCO.TRUCKS") &&
        <NavLink to={'/cco/trucks'} className={"farm-header-toggles"} activeClassName="plot-tog-active">
          <div id={'tabSetting'} className="">
            <FeatherIcon className="icon-in-tab farm-tab-icon-color" icon="truck"/><span
            className="farm-tab-yield">{t("title.TRUCKS")}</span></div>
        </NavLink>}
      {hasPermission("CCO.DISPATCH_NOTE") &&
        <NavLink to={'/cco/issue'} className={"farm-header-toggles"} activeClassName="plot-tog-active">
          <div id={'tabSetting'} className="">
            <FeatherIcon className="icon-in-tab farm-tab-icon-color" icon="book"/><span
            className="farm-tab-yield">{t("title.DISPATCH_NOTE")}</span></div>
        </NavLink>}
      {hasPermission("CCO.SUMMARY_REPORT") &&
        <NavLink to={'/cco/cco-report'} className={"farm-header-toggles"} activeClassName="plot-tog-active">
          <div id={'tabSetting'} className="">
            <FeatherIcon className="icon-in-tab farm-tab-icon-color" icon="file-text"/><span
            className="farm-tab-yield">{t("title.REPORT")}</span></div>
        </NavLink>}

    </div>
  )
};


export default CCOperationsTab
