import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import formHandler from "../../../shared/utils/FormHandler";
import {validateTruckSubmit} from "../../../utils/FormValidationRules";
import 'react-datepicker/dist/react-datepicker.css';
import {useDispatch, useSelector} from "react-redux";
import Container from "react-bootstrap/Container";
import axios from "axios";
import {toggleConfirmationDialog, toggleLoader} from "../../../shared/actions/setting";
import {toast} from "react-toastify";
import * as _ from "underscore";
import filterHandler from "../../../shared/utils/FilterHandler";
import {ExportToCsv} from "export-to-csv";
import AddTrucks from "./add-trucks"

import pdfImg from '../../../images/PDF.svg'
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";


const Trucks = (props) => {
  const {t, i18n} = useTranslation();
  const [isVisibleTruck, setIsVisibleTruck] = useState(false);
  const [isAddTrucks, setIsAddTrucks] = useState(false);
  const [isVisibleView, setIsVisibleView] = useState(false);
  const [truckList, setTruckList] = useState([]);
  const [truckListAll, setTruckListAll] = useState([]);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState("");
  const [updateIndex, setUpdateIndex] = useState([]);
  const [truckListUnFiltered, setTruckListUnFiltered] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [print, setPrint] = useState(false);

  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });
  const {
    values,
    errors,
    initForm,
    handleChange,
    handleSubmit,
    handleOnBlur,
    setValue,
  } = formHandler(truck, validateTruckSubmit);

  function truck() {
    setIsLoading(true);
  }


  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });


  useEffect(() => {
    if (!selectedCorporate) {
      return;
    }
    getAllTrucks();
  }, [selectedCorporate]);


  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess || !deleteId) {
      return;
    }
    // dispatch(resetConfirmationDialog());
    dispatch(toggleLoader(true));
    axios.delete(`${process.env.REACT_APP_HOST}/cco/user/${getUserId()}/corporate/${selectedCorporate}/truck/${deleteId}`)
      .then(res => {
        setTruckList(truckList.filter(item => item.id !== deleteId));
        toast.success(t("successMsg.TRUCK_DELETED_SUCCESSFULLY"));
        dispatch(toggleLoader(false));
      }).catch(error => {
      dispatch(toggleLoader(false));
      if (error.response && error.response.status === 422) {
        toast.warn(error.response.data.message);
      } else {
        toast.error("Something went Wrong");
      }
    })
  }, [confirmationDialog]);


  function getAllTrucks() {
    dispatch(toggleLoader(true))
    axios.get(`${process.env.REACT_APP_HOST}/cco/user/${getUserId()}/corporate/${selectedCorporate}/truck`)
      .then(res => {
        setTruckList(res.data.content);

        setTruckListUnFiltered(res.data.content);
        setTruckListAll(res.data.content);
      }).catch(error => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      }
    ).finally(() => {
      dispatch(toggleLoader(false))
    })

  }

  function handleDeleteTruck(id) {
    dispatch(toggleConfirmationDialog({
      isVisible: true,
      confirmationHeading: t("confirmation_msg.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_TRUCK?"),
      confirmationDescription: t("confirmation_msg.THE_DELETE_ACTION_WILL_REMOVE_THE_TRUCK.")
    }));
    setDeleteId(id);
  }

  function onEditClick(truckProp, index) {

    initForm(truckProp)
    setIsUpdateAvailable(true);
    setIsVisibleTruck(true);
    setUpdateIndex(index);
    setSelectedData(truckProp)
  }

  const {
    handleFilter,
    filteredList,
    filters
  } = filterHandler(setFilteredList, truckListAll);

  function setFilteredList() {
    setTruckListUnFiltered(filteredList);
    setTruckList(filteredList)
  }

  function exportData() {
    const data = [];
    truckList.forEach(truck => {
      data.push({
        "Truck Number ": truck.truckNumber,
        "Agreed Price": truck.agreedPrice,
        "Driver Name": truck.driverName,
        "Helper Name": truck.helperName,
      });
    });
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Trucks',
      useBom: true,
      noDownload: false,
      headers: ["Truck Number", "Agreed Price", "Driver Name", "Helper Name"],
      filename: "Trucks",
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);

  }

  function handleSearch(e) {
    let val = e.target.value;
    if (val !== "") {
      let res = _.filter(truckListUnFiltered, function (item) {
        return _.values(_.pick(item, 'agreedPrice', 'driverName', 'helperName', 'driverNic', 'helperNic')).toString().toLocaleLowerCase().includes(val.toLocaleLowerCase());
      });
      setTruckList(res);
    } else {
      setTruckList(truckListUnFiltered);
    }
  }

  function onPrint() {
    setPrint(true)

    setTimeout(function () {
      window.print();
    }, 500);
  }

  window.onafterprint = function () {
    setPrint(false);
  }

  return (
    <div hidden={!selectedCorporate}>
      <Container>
        <div className="single-card m-rl-m-8  p-a-16" hidden={isVisibleView}>
          <div className={'sa-table-flex sa-table-position'} style={{marginBottom: 8}}>
                        <span>
                            <span className="sa-table-btn-mute sa-search-align sa-table-float-left sa-truck-search">
                                <input className={'sa-table-search sa-table-search-point font-14'}
                                       name="search"
                                       onChange={handleSearch}
                                       autoComplete={"off"} type="text" placeholder={t("placeHolder.SEARCH")}/>
                                <FeatherIcon className={'sa-search-icon'}
                                             icon={"search"} width={'16px'}/>
                            </span>
                        </span>
            <span className={'sa-table-icon-flex sa-table-filter-position'}>

                        </span>
            <span className={'sa-table-icon-flex sa-table-filter-position'}>
                            <button onClick={() => setIsAddTrucks(true)}
                                    className="sa-table-btn-secondary sa-table-float-right">
                                <FeatherIcon
                                  icon={"plus"} className={"sa-table-icon-size"}/> <span
                              className={"sa-table-icon"}>{t("button.ADD")}</span>
                            </button>
              {/* <label disabled={truckList.length === 0} className="sa-table-btn-secondary sa-table-btn-mute sa-table-float-right">
                                <form >
                                    <input accept=".xlsx" id="file-upload" type="file" onChange={(e) => {const file = e.target.files[0];
                                                                                                        importData(file); }}/>
                                </form>
                                <FeatherIcon icon={"download"} className={"sa-table-icon-size"} /> <span className={"sa-table-icon"}>Import CSV</span>
                             </label> */}
              {/*<button onClick={() => setIsAddFarmerCsv(true)} className="sa-table-btn-secondary sa-table-btn-mute sa-table-float-right">*/}
              {/*    <FeatherIcon*/}
              {/*        icon={"download"} className={"sa-table-icon-size"} /> <span className={"sa-table-icon"}>Import</span>*/}
              {/*</button>*/}
              <button disabled={truckList.length === 0} onClick={exportData}
                      className="sa-table-btn-secondary sa-table-btn-mute sa-table-float-right">
                               <FeatherIcon
                                 icon={"upload"} className={"sa-table-icon-size"}/> <span
                className={"sa-table-icon"}>{t("button.EXPORT")}</span>
                            </button>
                        </span>
          </div>

          <div id="scrollableDiv" className="sa-table-container xindicate-calc-height">
            <table className="table table-borderless sa-table-width">
              <thead>
              <tr>
                <th className={"sa-table-head-sticky"}>{t("title.TRUCK_NO")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.AGREED_PRICE")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.DRIVER_NAME")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.HELPER_NAME")}</th>
                <th className="sa-table-item-align sa-table-head-sticky"></th>
              </tr>
              </thead>
              <tbody>

              {truckList.length > 0 && truckList.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
              }) && truckList.map((truckProp, index) =>
                <tr key={truckProp.id}>
                  <td className={'sa-table-data'}>{truckProp.truckNumber} </td>
                  {/* <td>{truckProp.truckDimensions}</td> */}

                  <td className={'sa-table-data'}>{truckProp.agreedPrice}</td>

                  <td className={'sa-table-data'}>{truckProp.driverName}</td>
                  <td className={'sa-table-data'}>{truckProp.helperName}</td>

                  <td className="sa-table-item-align">
                    <div className={'sa-table-action-flex'}>
                      {/* <div className="table-actions" data-toggle="tooltip" title={t("button.VIEW")}>
                                                <FeatherIcon
                                                    onClick={() => {
                                                        onViewClick(truckProp, index);
                                                    }}
                                                    className="table-action" icon={"eye"} /></div> */}
                      <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.EDIT")}>
                        <FeatherIcon
                          onClick={() => {
                            var truck = {
                              ...truckProp, height: truckProp.truckDimensions.HEIGHT,
                              width: truckProp.truckDimensions.WIDTH,
                              length: truckProp.truckDimensions.LENGTH
                            };

                            onEditClick(truck, index);
                          }}
                          className="table-action" icon={"edit"}/></div>
                      <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.DELETE")}>
                        <FeatherIcon
                          onClick={() => handleDeleteTruck(truckProp.id)}
                          className="text-red" icon={"trash-2"}/>
                      </div>

                      {/* <div className="table-actions" data-toggle="tooltip" title={t("title.AUDIT_LOG")}>
                                                <FeatherIcon
                                                    onClick={() => {
                                                        setIsUpdateAvailable(true);
                                                        setIsVisibleAuditLog(true);
                                                        setObjectId(truckProp.id);
                                                    }}
                                                    className="table-action" icon={"airplay"} />
                                            </div> */}
                    </div>


                  </td>

                </tr>)}
              </tbody>

            </table>
            {truckList.length === 0 && (
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_TRUCKS")}</div>
              </div>)}


          </div>
        </div>
        {isVisibleView && <div>
          <div className={'d-flex align-items-center pdf-hide'}>
            <div className={"mb-3 pdf-hide"}>
              <FeatherIcon icon={"arrow-left"} onClick={() => {
                setIsVisibleView(false)
                setSelectedData(null)
              }}
                           className={"sa-cursor"}/> {selectedData.firstName + " " + selectedData.lastName}
            </div>
            <div className={'pdf-hide pdf-download-btn ml-auto '} onClick={onPrint}>
              <img src={pdfImg} className={'mr-1'}/>
              {t("title.DOWNLOAD")}
            </div>
          </div>
          {/* <TruckView print={print} onPrint={onPrint} data={selectedData} /> */}
          <div className={'d-block pageBreak'}>
            {/* <FarmerEvaluation print={print} farmerId={selectedData.id} /> */}
          </div>
          <div className={'d-block pageBreak'}>
            {/* <FarmerActivities print={print} data={selectedData.id} /> */}
          </div>
          <div className={'d-block pageBreak'}>
            {/* <FarmerCrop print={print} id={selectedData.id} /> */}
          </div>

        </div>}

      </Container>
      {/* {isAddFarmerCsv && <AddFarmerCsv onClose={() => {

                setFarmersData([])
                setIsAddFarmerCsv(false)

            }} data={farmersData} />} */}
      {(isVisibleTruck || isAddTrucks) && <AddTrucks onClose={() => {
        setIsVisibleTruck(false)
        setSelectedData(null)
        setIsAddTrucks(false)
      }} data={selectedData} reUpdate={() => getAllTrucks()} edit={isVisibleTruck} add={isAddTrucks}/>}

      {/*-----------------------------------------------------------------View AuditLogPopup---------------------------------------------------            */}
      {/* {isVisibleAuditLog &&
                <AuditLogPopup onClose={() => { setIsVisibleAuditLog(false) }} objectId={objectId} />
            } */}

    </div>
  )

};
export default Trucks;
