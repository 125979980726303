import React from "react";
import {NavLink} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import {useTranslation} from "react-i18next";

const PlotTabSettings = (props) => {
  const {t, i18n} = useTranslation();

  return (
    <div className="text-center settings-plot tab-container">
      {/*<NavLink to={'/' + props.farmId + '/plot/' + props.plotId + '/sensor'} activeClassName="plot-tog-active">*/}
      {/*    <div id={'tabSensor'} className="plots-header-toggles"><FeatherIcon className={"icon-in-tab"} icon={"radio"}/><span className={"mobile-hide"}> Sensors</span></div>*/}
      {/*</NavLink>*/}
      {/*<NavLink to={'/' + props.farmId + '/plot/' + props.plotId + '/schedule'} activeClassName="plot-tog-active">*/}
      {/*  <div id={'tabIrrigation'} className="plots-header-toggles"><FeatherIcon className={"icon-in-tab"} icon={"clock"}/><span className={"mobile-hide"}> Irrigation</span></div>*/}
      {/*</NavLink>*/}
      {/*<NavLink to={'/' + props.farmId + '/plot/' + props.plotId + '/report'} activeClassName="plot-tog-active">*/}
      {/*    <div id={'tabReport'} className="plots-header-toggles"><FeatherIcon className={"icon-in-tab"} icon={"clipboard"}/><span className={"mobile-hide"}> Reports</span></div>*/}
      {/*</NavLink>*/}
      <NavLink to={'/' + props.farmId + '/settings/plot/' + props.plotId + '/setting'}
               activeClassName="plot-tog-active">
        <div id={'tabSetting'} className="plots-header-toggles"><FeatherIcon
          className={"icon-in-tab farm-tab-icon-color"} icon={"settings"}/><span
          className={"farm-tab"}> {t("title.SETTINGS")}</span></div>
      </NavLink>
      <NavLink to={'/' + props.farmId + '/settings/plot/' + props.plotId + '/info'} activeClassName="plot-tog-active">
        <div id={'tabInfo'} className="plots-header-toggles"><FeatherIcon className={"icon-in-tab"} icon={"info"}/><span
          className={"mobile-hide"}> {t("title.PLOT_INFO")}</span></div>
      </NavLink>
      <NavLink to={'/' + props.farmId + '/settings/plot/' + props.plotId + '/user'} activeClassName="plot-tog-active">
        <div id={'tabUser'} className="plots-header-toggles"><FeatherIcon className={"icon-in-tab"}
                                                                          icon={"users"}/><span
          className={"mobile-hide"}> {t("title.USERS")}</span></div>
      </NavLink>
    </div>
  )
};


export default PlotTabSettings
